import React, { useCallback, useEffect, useState } from "react";
import {
  Autocomplete,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import { IWorkById, IWorkTemplateById } from "apis/response";
import Apis from "apis/remotes";
import dayjs from "dayjs";
import { debounce } from "lodash";
import ClearIcon from "@mui/icons-material/Clear";

interface Props {
  open: boolean;
  handleOpenState: Function;
  handleSetFormLoadAnnouncement: Function;
}

function LoadOriginAnnounceDialog({
  open,
  handleOpenState,
  handleSetFormLoadAnnouncement,
}: Props): JSX.Element {
  const [works, setWorks] = useState<IWorkTemplateById[]>([]);
  const [selectedWork, setSelectedWork] = useState<IWorkTemplateById | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>("");

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (selectedWork) {
      const [startTime, endTime] = selectedWork.workTime.split("~");
      handleSetFormLoadAnnouncement({
        ...selectedWork,
        address: selectedWork.address,
        workDay: dayjs(selectedWork.workDay),
        workStart: dayjs(selectedWork.workStart),
        workEnd: dayjs(selectedWork.workEnd),
        workTime: [dayjs(startTime, "HH:mm:ss"), dayjs(endTime, "HH:mm:ss")],
        pay: selectedWork.pay,
        payType: selectedWork.payType,
      });
    }
    handleOpenState();
  };

  const loadMore = () => {
    if (works.length < totalPages) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  // Debounce search term with a 600ms delay
  const debounceSearch = useCallback(
    debounce((value: string) => {
      console.log("debounceSearch", value);
      setDebouncedSearchTerm(value);
      setWorks([]); // Reset works on new search
      setCurrentPage(1); // Reset page
    }, 300),
    []
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const resolve = await Apis.getTemplateLoadList({
          page: currentPage,
          perPage: 10,
          search: debouncedSearchTerm, // Add the search term here
        });
        setWorks((prev) => [...prev, ...resolve.data.data]);
        setTotalPages(resolve.data.total);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [currentPage, debouncedSearchTerm]); // Fetch data when page or search term changes
  // ✅ 특정 옵션 삭제 함수
  const handleRemoveOption = (workId: number) => {
    setWorks((prevWorks) => prevWorks.filter((work) => work.workId !== workId));
  };
  return (
    <Dialog open={open} onClose={() => handleOpenState()} fullWidth>
      <form onSubmit={handleSubmit}>
        <DialogTitle>
          <MDBox display="flex" justifyContent="center">
            동일 현장 공고 불러오기
          </MDBox>
        </DialogTitle>

        <DialogContent>
          <MDBox mb={3}>
            <Autocomplete
              value={selectedWork}
              onChange={(event, newValue) => {
                setSelectedWork(newValue);
              }}
              options={works}
              isOptionEqualToValue={(option, value) => option.workId === value.workId}
              getOptionLabel={(option) =>
                `[${option.workId}]${option.title} | ${
                  option.address
                } / ${option.pay?.toLocaleString()}원`
              }
              onInputChange={(event, value) => {
                debounceSearch(value); // Trigger the debounce function here
              }}
              renderInput={(params) => (
                <MDInput {...params} variant="standard" placeholder="공고를 선택하세요" />
              )}
              // ✅ 옵션 목록에서 오른쪽에 X 버튼 추가
              renderOption={(props, option) => (
                <li {...props} key={option.workId} className="flex justify-between w-full">
                  <span>
                    [{option.workId}] {option.title} | {option.address} /{" "}
                    {option.pay?.toLocaleString()}원
                  </span>
                  <IconButton
                    size="small"
                    onClick={(event) => {
                      event.stopPropagation(); // 옵션 선택 방지
                      handleRemoveOption(option.workId);
                    }}
                    edge="end"
                  >
                    <ClearIcon />
                  </IconButton>
                </li>
              )}
              ListboxProps={{
                onScroll: (event) => {
                  const listboxNode = event.currentTarget;
                  const scrollPosition = listboxNode.scrollTop + listboxNode.clientHeight; // 현재 스크롤 위치
                  const threshold = listboxNode.scrollHeight * 0.8; // 스크롤 높이의 80%

                  if (scrollPosition >= threshold && loading === false) {
                    loadMore();
                  }
                },
              }}
            />
          </MDBox>
          <MDBox>동일 현장 공고를 불러올 경우 공고제목과 현장 주소 변경은 불가합니다.</MDBox>
        </DialogContent>
        <DialogActions>
          <MDButton onClick={() => handleOpenState()}>취소</MDButton>
          <MDButton type="submit" color="info" disabled={selectedWork === null}>
            불러오기
          </MDButton>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default LoadOriginAnnounceDialog;
