import {
  IGetFilesUpload,
  getList,
  IGetUserById,
  IEmploymentMy,
  IWorks,
  IWorkById,
  fileUploadTarget,
  fileUplaodType,
  IWorkPostForm,
  IEmploymentForm,
  IMatchs,
  IMatchRecuit,
  IMatchApply,
  ISettlements,
  IUserSettlementById,
  UserCurrentMatchType,
  IUserSettlement,
  IMatchEvaluationForm,
  IMatchCurrentSix,
  IMatchCurrentApplyFive,
  IExps,
  IExpById,
  IExpWork,
  IExpWorkById,
  INewSettlement,
  INewSettlementById,
  IExpWorkList,
  IExpWorkUser,
  IExpWorkDayList,
  INewWork,
  WorkData,
  IDashBoardGraph,
  IWorkPartnerListById,
  IWorkPartnerList,
  IBugs,
  IUtilImages,
  IAuth,
  IfieldEvaluationWork,
  IFieldEvaluation,
  IBuddy,
  IInsuranceDetail,
  IGetUsers,
  ICodeListCity,
  ICodeListCityDistrict,
  IWorkTemplateById,
  IWorkSignatureById,
  IGetUserAbsence,
} from "apis/response";
import { api } from "apis/axios";

const Apis = {
  /** 인력사무소 로그인 세션 체크 */
  getAuth() {
    return api.get<IAuth>("auth");
  },
  /** 인력사무소 로그인 */
  postAuth(option: any) {
    return api.post("auth", option);
  },

  /** 인력사무소 로그아웃 */
  deleteAuth() {
    return api.delete("auth");
  },

  /** 인력사무소 가입 */
  postEmployment(option: IEmploymentForm) {
    return api.post("employment", option);
  },
  /** 인력사무소 마이페이지 */
  getEmploymentMy() {
    return api.get<IEmploymentMy>("employment/my");
  },
  /** 인력사무소 정보 수정(본인) */
  putEmployment(option: any) {
    return api.put(`employment`, option);
  },
  /** 인력사무소 탈퇴 */
  deleteEmployment(id: string | number) {
    return api.delete(`employment/${id}`);
  },

  /** 인력사무소 정보 수정(본인) */
  putEmploymentPasswordChange(option: any) {
    return api.put(`employment/password/change`, option);
  },

  /** Get pre-signed url */
  postFilesUpload({
    type,
    mimeType,
    imageUploadTarget,
    num = 1,
  }: {
    type: fileUplaodType;
    mimeType: string;
    imageUploadTarget: fileUploadTarget;
    num?: number;
  }) {
    return api.get<IGetFilesUpload[]>(`file/upload`, {
      params: {
        type,
        mimeType,
        imageUploadTarget,
        num,
      },
    });
  },

  /** 대시보드 오늘 */
  getDashBoardToday(option: any) {
    return api.get<
      getList<WorkData> & {
        totalMaxRecruitment: number;
        totalWorkApplyCount: number;
        totalCurrentRecruitment: number;
        totalGoWorkCount: number;
        totalWorkAbsenceCount: number;
        totalUnaccountedCount: number;
      }
    >(`dashboard/today`, { params: option });
  },
  /** 대시보드 내일 */
  getDashBoardTomorrow(option: any) {
    return api.get<
      getList<WorkData> & {
        totalMaxRecruitment: number;
        totalWorkApplyCount: number;
        totalCurrentRecruitment: number;
        totalGoWorkCount: number;
        totalWorkAbsenceCount: number;
      }
    >(`dashboard/tomorrow`, { params: option });
  },
  /** 대시보드 그래프 */
  getDashBoardGraph() {
    return api.get<IDashBoardGraph>(`dashboard/graph`);
  },

  /** 오류 제보 */
  postDashBoardBug(option: any) {
    return api.post(`dashboard/bug`, option);
  },
  /** 신고한 오류 조회 */
  getDashBoardBug(option: any) {
    return api.get<getList<IBugs>>(`dashboard/bug`, { params: option });
  },

  /** 매칭 리스트 조회 */
  getMatchs(option: any) {
    return api.get<getList<IMatchs>>(`match`, {
      params: option,
    });
  },
  /** 공고지원 승인(지원 합격) */
  putMatchApprove(id: string | number) {
    return api.put(`match/approve/${id}`);
  },
  /** 결근 처리 출근으로 변경 */
  putMatchAbsenceCancelById(id: string | number) {
    return api.put(`match/absence/cancel/${id}`);
  },
  /** 공고지원 거절(지원 미승인)
   * 해당 공고 지원자를 마감또는 확정인원이 정해져서 패털티 없이 미승인.
   */
  putMatchReject(id: string | number) {
    return api.put(`match/reject/${id}`);
  },
  /** 패널티 - 공고지원 거절(지원 미승인)
   * 해당 공고 지원자를 악성 사용자라고 판단하여 패널티를 주고 미승인.
   */
  putMatchRejectPenalty(id: string | number) {
    return api.put(`match/reject-penalty/${id}`);
  },
  /** 지원확정 인원 취소
   * 확정된 인원에서 해당 사용자를 패널티 없이 제외한다.
   */
  putMatchRejectRecruit(id: string | number) {
    return api.put(`match/reject-recruit/${id}`);
  },
  /** 패널티 - 지원확정 인원 취소
   * 확정된 인원에서 해당 사용자를 악성 사용자라고 판단하여 제외한다.
   */
  putMatchRejectRecruitPenalty(id: string | number) {
    return api.put(`match/reject-recruit-penalty/${id}`);
  },
  /** 근무 확정인원 조회 */
  getMatchRecruit(id: string | number) {
    return api.get<getList<IMatchRecuit>>(`match/recruit/${id}`);
  },
  /** 현장 1~4단계 조회(지원/출근/퇴근) */
  getMatchCurrentApply(id: string | number, optoin: { status: UserCurrentMatchType }) {
    return api.get<getList<IMatchApply>>(`match/current-apply/${id}`, { params: optoin });
  },
  /** 현장 5단계 조회(입금) */
  getMatchCurrentApplyFive(id: string | number) {
    return api.get<getList<IMatchCurrentApplyFive>>(`match/current-apply-five/${id}`);
  },
  /** 현장 6단계 조회(평가) */
  getMatchCurrentApplySix(id: string | number) {
    return api.get<getList<IMatchCurrentSix>>(`match/current-apply-six/${id}`);
  },

  /** 매칭 전체 인원 조회 */
  getMatchApply(option: any) {
    return api.get<getList<IMatchApply>>(`match/apply`, { params: option });
  },
  /** 출근확정 */
  putMatchConfirmWork(id: string | number) {
    return api.put(`match/confirm-work/${id}`);
  },
  /** 출근확인 */
  putMatchGoWork(id: string | number) {
    return api.put(`match/go-work/${id}`);
  },
  /** 퇴근확인 */
  putMatchOffWork(id: string | number) {
    return api.put(`match/off-work/${id}`);
  },
  /** 유저평가 */
  postMatchEvaluation(option: any) {
    return api.post(`match/evaluation`, option);
  },
  /** 유저평가 수정 */
  putMatchEvaluationById(id: string | number, option: Pick<IMatchEvaluationForm, "score">) {
    return api.put(`match/evaluation/${id}`, option);
  },
  /** 결근 처리 */
  putMatchAbsenceById(id: string | number) {
    return api.put(`match/absence/${id}`);
  },
  /** 출근 확인 요청 알람 */
  postMatchGoWorkFcm(option: { userId: number; workId: number }) {
    return api.post(`match/go-work-fcm`, option);
  },
  /** 퇴근 확인 요청 알람 */
  postMatchOffWorkFcm(option: { userId: number; workId: number }) {
    return api.post(`match/off-work-fcm`, option);
  },

  /** 입금완료 id -> settlementId */
  putSettlementDepositById(id: string | number) {
    return api.put(`settlement/deposit/${id}`);
  },
  /** 입금 수정 */
  putSettlementById(id: string | number, option: any) {
    return api.put(`settlement/${id}`, option);
  },
  /** 유저 정산 조회 id -> userId */
  getUserSettlemntById(id: string | number, option: any) {
    return api.get<getList<IUserSettlementById>>(`settlement/${id}`, { params: option });
  },
  /** 정산 조회 */
  getSettlements(options: any) {
    return api.get<getList<ISettlements>>(`settlement`, { params: options });
  },
  /** new 정산 조회 */
  getSettlement(option: any) {
    return api.get<
      getList<INewSettlement> & {
        totalSettlementUserCount: number; // 정산 인원 수 +  미정산 인원 수
        settledUserCount: number; // 정산 인원 수
        unsettledUserCount: number; // 미정산 인원 수
        totalManDayCount: number; // 전체 공수
        finalTotalAmount: number; // 정산 금액 + 미정산 금액
        settledTotalAmount: number; // 정산 금액
        unsettledTotalAmount: number; // 미정산 금액
      }
    >(`settlement`, { params: option });
  },
  /** new 정산 상세 조회 */
  getSettlementById(id: string | number) {
    return api.get<getList<INewSettlementById>>(`settlement/${id}`);
  },
  /** 정산 조회(사용자) */
  getSettlementUserList(option: any) {
    return api.get<getList<IUserSettlement>>(`settlement/user/list`, { params: option });
  },

  /** 시/도 코드 테이블 */
  getCodeListCity() {
    return api.get<getList<ICodeListCity>>(`codelist/city`);
  },

  /** 시/군/구 코드 테이블 */
  getCodeListCityDistrict(cityName: string) {
    return api.get<getList<ICodeListCityDistrict>>(`codelist/city/${cityName}`);
  },

  /** Get file download */
  getFileDownLoad(option: any) {
    return api.get<IGetFilesUpload>(`file/download`, { params: option });
  },
  /** 유저 리스트 조회 */
  getUsers(option: any) {
    return api.get<getList<IGetUsers>>(`user`, { params: { ...option } });
  },

  /** 유저 상세조회 */
  getUserById(id: string | number) {
    return api.get<IGetUserById>(`user/${id}`);
  },
  /** 유저 상세조회 - 고용보험 */
  getUserInsuranceById(id: string | number) {
    return api.get<IInsuranceDetail>(`user/insurance/${id}`);
  },

  /** 유저 buddy 조회 */
  getBuddy(id: string | number, option: any) {
    return api.get<getList<IBuddy>>(`user/buddy/${id}`, { params: option });
  },

  /** 결근 유저 리스트 조회 */
  getUserAbsenceList(option: any) {
    return api.get<getList<IGetUserAbsence>>(`user/absence/list`, { params: { ...option } });
  },

  /** 공고 등록 */
  postWork(option: IWorkPostForm) {
    return api.post(`work`, option);
  },
  /** 작성한 공고 조회 */
  getWork(option: any) {
    return api.get<getList<IWorks>>("work", {
      params: option,
    });
  },
  /** new 공고 조회 */
  getNewWork(option: any) {
    return api.get<getList<INewWork>>(`work`, { params: option });
  },

  /** 공고 숨기기 */
  putWorkShowById(id: string | number) {
    return api.put(`work/show/${id}`);
  },

  /** new 예약 공고 조회 */
  getNewWorkReserve(option: any) {
    return api.get<getList<INewWork>>(`work/reserve`, { params: option });
  },
  /** 공고 수정 */
  putWork(id: string | number, option: IWorkPostForm) {
    return api.put(`work/${id}`, option);
  },
  /** 공고 상세 조회 */
  getWorkById(id: string | number) {
    return api.get<IWorkById>(`work/${id}`);
  },
  /** 공고 삭제 */
  deleteWork(id: string | number) {
    return api.delete(`work/${id}`);
  },
  /** 공고 마감 */
  putWorkOff(id: string | number) {
    return api.put(`work/off/${id}`);
  },
  /** 공고 자동 마감 ON/OFF
   *
   * 공고가 수동 마감되면 해당 공고는 직접 마감하기 전까지 마감되지 않음.
   */
  putWorkOnOffManual(id: string | number) {
    return api.put(`work/work-end/${id}`);
  },

  /** 공고 리스트 불러오기 */
  getWorkLoadList(option: any) {
    return api.get<getList<IWorkById>>(`work/load/list`, { params: option });
  },

  /** 공고 템플릿 리스트 불러오기 */
  getTemplateLoadList(option: any) {
    return api.get<getList<IWorkTemplateById>>(`work/template/list`, { params: option });
  },

  /** 공고 템플릿 가리기 */
  putTemplateOnOffById(id: string | number) {
    return api.put(`work/template/${id}`);
  },

  /** 공고 인원 수정 */
  putWorkRecruit(id: string | number, option: any) {
    return api.put(`work/recruitment/${id}`, option);
  },

  /** 현장 파트너 조회 */
  getWorkPartnerList(option: any) {
    return api.get<getList<IWorkPartnerList>>(`work/partner/list`, { params: option });
  },
  /** 현장 파트너 상세조회 */
  getWorkPartnerListById(id: number | string) {
    return api.get<getList<IWorkPartnerListById>>(`work/partner/list/${id}`);
  },
  /** 현장 파트너 on/off */
  putWorkPartnerListOnOff(option: any) {
    return api.put(`work/partner/list`, option);
  },
  /** 싸인지 확인 */
  putWorkSignatureById(id: string | number) {
    return api.put(`work/signature/${id}`);
  },

  /** 싸인지 조회 */
  getWorkSignatureById(id: string | number) {
    return api.get<getList<IWorkSignatureById>>(`work/signature/${id}`);
  },

  /** 이력관리 조회 미사용*/
  // getExp(option: any) {
  //   return api.get<getList<IExps>>(`experience`, { params: option });
  // },
  /** 이력관리 상세 조회 미사용*/
  // getExpById(id: string | number, option: any) {
  //   return api.get<getList<IExpById>>(`experience/${id}`, { params: option });
  // },
  /** New 근로자 목록 조회 */
  getExpWorkList(option: any) {
    return api.get<getList<IExpWorkList>>(`experience/work/list`, { params: option });
  },
  /** New 근무중인 근로자 목록 조회 */
  getExpWorkingList(option: any) {
    return api.get<getList<IExpWorkList>>(`experience/working/list`, { params: option });
  },
  /** New 근로자 목록 상세 조회 상단(유저 상세 조회 정보) */
  getExpWorkListById(id: string | number) {
    return api.get<IExpWorkUser>(`experience/work/list/${id}`);
  },
  /** New 근로자 목록 상세 조회 하단(유저 근무 리스트 정보) */
  getExpWorkListDayById(id: string | number) {
    return api.get<getList<IExpWorkDayList>>(`experience/work/list/day/${id}`);
  },
  /** 근로내역 조회 미사용*/
  // getExpWork(option: any) {
  //   return api.get<getList<IExpWork>>(`experience/work/list`, { params: option });
  // },
  /** 근로내역 상세 조회 미사용*/
  // getExpWorkById(id: string | number, option: any) {
  //   return api.get<getList<IExpWorkById>>(`experience/work/list/${id}`, { params: option });
  // },

  /** 공고 등록용 이미지 목록 */
  getUtilImageRandom(option: any) {
    return api.get<getList<IUtilImages>>(`util/image/random`, { params: option });
  },

  /** 근로자 평가 사이트 입장 */
  getFieldEvaluationByPhone(phone: string) {
    return api.get<getList<IFieldEvaluation>>(`field/evaluation/${phone}`);
  },
  /** 근로자 평가 리스트 조회 */
  getFieldEvaluationWorkById(id: string | number) {
    return api.get<getList<IfieldEvaluationWork>>(`field/evaluation/work/${id}`);
  },
  /** 근로자 평가 기록 */
  postFieldEvaluation(option: any) {
    return api.post(`field/evaluation`, option);
  },
};

export default Apis;

// React에서 주소를 좌표로 변환하는 예시 코드
// import axios from 'axios';

// const convertAddressToCoordsGoogle = async (address) => {
//   try {
//     const response = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json`, {
//       params: { address: address, key: 'YOUR_GOOGLE_API_KEY' }
//     });
//     const { lat, lng } = response.data.results[0].geometry.location;
//     return { lat, lng };
//   } catch (error) {
//     console.error(error);
//   }
// };

// React에서 주소를 좌표로 변환하는 예시 코드
// import axios from 'axios';

// const convertAddressToCoordsNaver = async (address) => {
//   try {
//     const response = await axios.get(`https://openapi.naver.com/v1/map/geocode`, {
//       params: { query: address },
//       headers: {
//         'X-Naver-Client-Id': 'YOUR_NAVER_CLIENT_ID',
//         'X-Naver-Client-Secret': 'YOUR_NAVER_CLIENT_SECRET'
//       }
//     });
//     const { x, y } = response.data.result.items[0].point;
//     return { lat: y, lng: x };
//   } catch (error) {
//     console.error(error);
//   }
// };

// React에서 주소를 좌표로 변환하는 예시 코드
// import axios from 'axios';

// const convertAddressToCoords = async (address) => {
//   try {
//     const response = await axios.get(`https://dapi.kakao.com/v2/local/search/address.json`, {
//       params: { query: address },
//       headers: { Authorization: 'KakaoAK {REST_API_KEY}' }
//     });
//     const { lat, lng } = response.data.documents[0].address;
//     return { lat, lng };
//   } catch (error) {
//     console.error(error);
//   }
// };
